import { Component, OnInit } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { register } from 'swiper/element/bundle';
import { ToastController } from '@ionic/angular';
import { App } from '@capacitor/app';
import { environment } from "../environments/environment";
import { SendData } from './send-data';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { initializeApp } from "firebase/app";
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  message: any = null;
  constructor(
    private platform: Platform,
    public senddata: SendData,
    private toastController: ToastController,
  ) {
    this.initializeFirebase();
    App.addListener('appRestoredResult', data => {
      console.log('Restored state:', data);
    });

    const checkAppLaunchUrl = async () => {
      const { url } = await App.getLaunchUrl();

      console.log('App opened with URL: ' + url);
    };

    Network.addListener('networkStatusChange', async (status) => {
      if(status.connected != true) {
        const toast = await this.toastController.create({
          header: 'No internet connection detected',
          message: 'Please restart your network and try again.',
        });
        await toast.present();
        setTimeout(() => {
          toast.dismiss();
        }, 1000);
      } else {
        console.log('Network status changed', status);
      }
    });
  }

  public async initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebaseConfig);
  }

  async ngOnInit() {
    // Wait until platform is ready
    this.platform.ready().then(async () => {

      // If we're on a mobile platform (iOS / Android), not web
      if (Capacitor.getPlatform() !== 'web') {

        // Display content under transparent status bar (Android only)
        StatusBar.setOverlaysWebView({ overlay: false });

        // Set StatusBar style (dark / light)
        await StatusBar.setStyle({ style: Style.Light });
        // await StatusBar.hide();
      }
    });
    const status = await Network.getStatus();
    if(status.connected != true) {
      const toast = await this.toastController.create({
        header: 'No internet connection detected',
        message: 'Please restart your network and try again.',
      });
      await toast.present();
      setTimeout(() => {
        toast.dismiss();
      }, 1000);
    } else {
      console.log('Network status:', status);
    }
  }
}
