import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SendData {
  serverapi : string = "https://cms.salamdjourney.com/cms/api"; //server-production
  // serverapi : string = "https://cms.madanidjourney.com/cms/api"; //server-development
  controllerapi: string = this.serverapi + "/";
  api_key = 'valar';
  fcm_key: string = "AAAAoj-xyxY:APA91bELg3rTSrdZPnGf90ffy3lUBvtjVQZy-Cl65ticspZ8i1iM-WsdtwNIKqFhJAwo8_02MBp-j5BNeZi4B_GUYvK5xpUWXqnWCutpUeawajNl9vBJJDaBKLjraQSYzkqnFtdiEVRU";
  latestBUSD: string = "https://production.api.coindesk.com/v2/tb/price/ticker?assets=BUSD";
  latestBNB: string = "https://production.api.coindesk.com/v2/tb/price/ticker?assets=BNB";

  constructor(private httpClient: HttpClient) 
  {
      
  }

  authLogin(
    emailUser: string = "",
    passUser: string = "",
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("emailUser", emailUser);
    body.append("passUser", passUser);

    return this.httpClient.post(this.controllerapi + "authLogin", body, {
      headers: header,
      responseType: "text",
    });
  }

  authLoginGoogle(
    nmUser: string = "",
    emailUser: string = "",
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("nmUser", nmUser);
    body.append("emailUser", emailUser);

    return this.httpClient.post(this.controllerapi + "authLoginGoogle", body, {
      headers: header,
      responseType: "text",
    });
  }

  gethotels(
    key: string = "",
    latitude: string ="",
    longitude: string ="",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "gethotels", body, {
      headers: header,
      responseType: "text",
    });
  }

  getAvailableRooms(
    arrival: string = "",
    departure: string = "",
    idBusiness: string = "",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("arrival", arrival)
    .set("departure", departure)
    .set("idBusiness", idBusiness)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "ajaxCheckOccupancy_byDate_arrival", body, {
      headers: header,
      responseType: "text",
    });
  }

  getAvailableRoomsLowest(
    arrival: string = "",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("arrival", arrival)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "ajaxCheckOccupancy_byLowest_arrival", body, {
      headers: header,
      responseType: "text",
    });
  }

  search_packages(
    idKamar: string = "",
    arrival: string = "",
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("idKamar", idKamar);
    body.append("arrival", arrival);

    return this.httpClient.post(this.controllerapi + "search_packages", body, {
      headers: header,
      responseType: "text",
    });
  }

  getInvoice(
    idOrder: string = ""
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("idOrder", idOrder);

    return this.httpClient.post(this.controllerapi + "jamaah", body, {
      headers: header,
      responseType: "text",
    });
  }

  generateInvoice(
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");

    return this.httpClient.post(this.controllerapi + "generateInvoice", {
      headers: header,
      responseType: "text",
    });
  }

  generateInvoiceFNB(
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");

    return this.httpClient.post(this.controllerapi + "generateInvoiceFNB", {
      headers: header,
      responseType: "text",
    });
  }

  // Search city 
  searchProvinces(query: string): Observable<string[]> {
    // Adjust the API endpoint and query parameters based on your server-side implementation
    const url = `${this.controllerapi}getcity?query=${query}`;
    return this.httpClient.get<string[]>(url);
  }

  getHotelsbyId(
    idBusiness: string = ""
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("idBusiness", idBusiness);

    return this.httpClient.post(this.controllerapi + "gethotelsbyid", body, {
      headers: header,
      responseType: "text",
    });
  }

  getHotelsGallerybyId(
    idBusiness: string = ""
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("idBusiness", idBusiness);

    return this.httpClient.post(this.controllerapi + "getHotelsGallerybyId", body, {
      headers: header,
      responseType: "text",
    });
  }

  getHotelsGuestroombyId(
    idBusiness: string = ""
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("idBusiness", idBusiness);

    return this.httpClient.post(this.controllerapi + "getHotelsGuestroombyId", body, {
      headers: header,
      responseType: "text",
    });
  }

  getHotelsSwimmingpoolbyId(
    idBusiness: string = ""
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("idBusiness", idBusiness);

    return this.httpClient.post(this.controllerapi + "getHotelsSwimmingpoolbyId", body, {
      headers: header,
      responseType: "text",
    });
  }

  getHotelsOffers(    
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();

    return this.httpClient.post(this.controllerapi + "getHotelsOffers", body, {
      headers: header,
      responseType: "text",
    });
  }

  getHotelsOffersbyId(
    idBusiness: string = ""
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("idBusiness", idBusiness);

    return this.httpClient.post(this.controllerapi + "getHotelsOffersbyId", body, {
      headers: header,
      responseType: "text",
    });
  }

  getOffersbyId(
    idVouchercatalog: string = ""
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new FormData();
    body.append("idVouchercatalog", idVouchercatalog);

    return this.httpClient.post(this.controllerapi + "getOffersbyId", body, {
      headers: header,
      responseType: "text",
    });
  }

  checkVoucherUser(): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      // .set("idUser", idUser)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "checkVoucherUser", body, {
      headers: header,
      responseType: "text",
    });
  }

  claimVoucherId(
    idUser: string = "",
    idVoucher: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("idVoucher", idVoucher)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "claimVoucherId", body, {
      headers: header,
      responseType: "text",
    });
  }

  checkVoucherClaimed(
    idUser: string = "",
    idVoucher: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("idVoucher", idVoucher)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "checkVoucherClaimed", body, {
      headers: header,
      responseType: "text",
    });
  }

  setActualPrice(
    actualPrice: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("actualPrice", actualPrice)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "setActualPrice", body, {
      headers: header,
      responseType: "text",
    });
  }

  insertBooking(
    data: string = "",
    virtual_account_number: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("data", data)
      .set("virtual_account_number", virtual_account_number)
    return this.httpClient.post(this.controllerapi + "insertBooking", body, {
      headers: header,
      responseType: "text",
    });
  }

  getBookingCheckout(
    idUser: string = "",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("key", key)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getBookingCheckout", body, {
      headers: header,
      responseType: "text",
    });
  }

  getBookingCheckin(
    idUser: string = "",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("key", key)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getBookingCheckin", body, {
      headers: header,
      responseType: "text",
    });
  }

  getBookingCheckinRoomService(
    idUser: string = "",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("key", key)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getBookingCheckinRoomService", body, {
      headers: header,
      responseType: "text",
    });
  }

  getBookingCheckinDetail(
    idUser: string = "",
    key: string = "",
    idBooking: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("key", key)
      .set("idBooking", idBooking)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getBookingCheckinDetail", body, {
      headers: header,
      responseType: "text",
    });
  }

  setClaimBooking(
    idUser: string = "",
    idBooking: string = "",
    invoiceBooking: string = "",
    idBusiness: string = "",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("idBooking", idBooking)
      .set("invoiceBooking", invoiceBooking)
      .set("idBusiness", idBusiness)
      .set("key", key)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "setClaimBooking", body, {
      headers: header,
      responseType: "text",
    });
  }

  getClaimBooking(
    idUser: string = "",
    idBooking: string = "",
    invoiceBooking: string = "",
    idBusiness: string = "",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("idBooking", idBooking)
      .set("invoiceBooking", invoiceBooking)
      .set("idBusiness", idBusiness)
      .set("key", key)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getClaimBooking", body, {
      headers: header,
      responseType: "text",
    });
  }

  getFNB(
    idBusiness: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("idBusiness", idBusiness)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getFNB", body, {
      headers: header,
      responseType: "text",
    });
  }

  getFNBByName(
    nmMenu: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("nmMenu", nmMenu)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getFNBByName", body, {
      headers: header,
      responseType: "text",
    });
  }

  getFNBBeverage(
    idBusiness: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("idBusiness", idBusiness)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getFNBBeverage", body, {
      headers: header,
      responseType: "text",
    });
  }

  getFNBType(
    idBusiness: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("idBusiness", idBusiness)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getFNBType", body, {
      headers: header,
      responseType: "text",
    });
  }

  getFNBByType(
    idBusiness: string = "",
    name: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("idBusiness", idBusiness)
      .set("name", name)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getFNBByType", body, {
      headers: header,
      responseType: "text",
    });
  }

  insertFNB(data: string = ""): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("data", data)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "insertFNB", body, {
      headers: header,
      responseType: "text",
    });
  }

  getProfile(
    idUser: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", this.api_key);
    let body = new HttpParams()
      .set("idUser", idUser)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "getProfile", body, {
      headers: header,
      responseType: "text",
    });
  }

  addCheckIn(
    idUser: string = "",
    key: string = "",
    idBooking: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Api-Key", key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("key", key)
      .set("idBooking", idBooking)
      // console.log(header);
    return this.httpClient.post(this.controllerapi + "addCheckIn", body, {
      headers: header,
      responseType: "text",
    });
  }

  getAllBusiness(
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getAllBusiness", body, {
      headers: header,
      responseType: "text",
    });
  }

  getCities(
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getCities", body, {
      headers: header,
      responseType: "text",
    });
  }

  getPlaces(
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getPlaces", body, {
      headers: header,
      responseType: "text",
    });
  }

  getPlacesById(
    idBusiness: string ="",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("idBusiness", idBusiness)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getPlacesById", body, {
      headers: header,
      responseType: "text",
    });
  }

  getCitiesById(
    id: string ="",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("id", id)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getCitiesById", body, {
      headers: header,
      responseType: "text",
    });
  }

  getPackages(
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getPackages", body, {
      headers: header,
      responseType: "text",
    });
  }

  getPackagesbyId(
    idKamar: string ="",
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("idKamar", idKamar)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getPackagesbyId", body, {
      headers: header,
      responseType: "text",
    });
  }

  nearbyHotel(
    latitude: string ="",
    longitude: string ="",
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("latitude", latitude)
    .set("longitude", longitude)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "nearbyHotel", body, {
      headers: header,
      responseType: "text",
    });
  }

  getDokuStatus(
    clientId: string = "",
    sharedKey: string = "",
    invoice: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Request-Timestamp", "2023-12-20T07:30:14Z")
    .set("Signature", "HMACSHA256=vl9DBTX5KhEiXmnpOD0TSm8PYQknuHPdyHSTSc3W6Ps=")
    .set("Request-Id", "e71fe02a-bfef-4af9-a6f6-2cf1f03b00e7")
    .set("Client-Id", clientId)
    let body = new HttpParams()
    .set("clientId", clientId)
    .set("sharedKey", sharedKey)
    .set("invoice", invoice)

    return this.httpClient.post('https://api.doku.com/orders/v1/status/', body, {
      headers: header,
      responseType: "text",
    });
  }

  getAllWishlist(
    idUser: string = "",
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("idUser", idUser)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getAllWishlist", body, {
      headers: header,
      responseType: "text",
    });
  }

  getWishlistbyId(
    idUser: string = "",
    idBusiness: string = "",
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("idUser", idUser)
    .set("idBusiness", idBusiness)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getWishlistbyId", body, {
      headers: header,
      responseType: "text",
    });
  }

  setWhistlist(
    idUser: string = "",
    idBusiness: string = "",
    statusWhistlist: string = "",
    key: string = ""
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("idUser", idUser)
    .set("idBusiness", idBusiness)
    .set("statusWhistlist", statusWhistlist)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "setWhistlist", body, {
      headers: header,
      responseType: "text",
    });
  }

  getHotelsFacility(
    idBusiness: string = "",
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new HttpParams()
    .set("idBusiness", idBusiness)

    return this.httpClient.post(this.controllerapi + "getHotelsFacility", body, {
      headers: header,
      responseType: "text",
    });
  }

  getRoomsFacility(
    idBusiness: string = "",
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new HttpParams()
    .set("idBusiness", idBusiness)

    return this.httpClient.post(this.controllerapi + "getRoomsFacility", body, {
      headers: header,
      responseType: "text",
    });
  }

  getHotelsRating(
    idBusiness: string = "",
  ): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "plain/text");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("enctype", "multipart/form-data");
    header.append("No-Auth", "True");
    let body = new HttpParams()
    .set("idBusiness", idBusiness)

    return this.httpClient.post(this.controllerapi + "getHotelsRating", body, {
      headers: header,
      responseType: "text",
    });
  }

  postNotificationOwnerBidmp(
    currentToken: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + this.fcm_key);
    let body = new HttpParams()
      .set("currentToken", currentToken)

    return this.httpClient.post(
      this.controllerapi +
        "postNotificationOwnerBid_mp/" +
        currentToken,
      body,
      { headers: header, responseType: "text" }
    );
  }

  postNotificationBidmp(
    idUser: string = "",
    currentToken: string = "",
    message: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + this.fcm_key);
    let body = new HttpParams()
      .set("idUser", idUser)
      .set("currentToken", currentToken)
      .set("message", message)

    return this.httpClient.post(this.controllerapi + "postNotificationBid_mp/" + idUser + '/' + currentToken + '/' + message, body, { 
      headers: header, 
      responseType: "text",
    });
  }

  uploadTF(formData: FormData): Observable<any> {
    return this.httpClient.post(this.controllerapi + "uploadTF", formData);
  }

  checkNotif(
    idUser: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    let body = new HttpParams()
    .set("idUser", idUser)

    return this.httpClient.post(this.controllerapi + "checkNotif", body, {
      headers: header,
      responseType: "text",
    });
  }

  updateNotif(
    idUser: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    let body = new HttpParams()
    .set("idUser", idUser)

    return this.httpClient.post(this.controllerapi + "updateNotif", body, {
      headers: header,
      responseType: "text",
    });
  }

  getNotif(
    idUser: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    let body = new HttpParams()
    .set("idUser", idUser)

    return this.httpClient.post(this.controllerapi + "getNotif", body, {
      headers: header,
      responseType: "text",
    });
  }

  updateNotifById(
    idNotification: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    let body = new HttpParams()
    .set("idNotification", idNotification)

    return this.httpClient.post(this.controllerapi + "updateNotifById", body, {
      headers: header,
      responseType: "text",
    });
  }

  getAllFoodOrder(
    idUser: string = "",
    key: string = "",
    invoice: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    let body = new HttpParams()
    .set("idUser", idUser)
    .set("key", key)
    .set("invoice", invoice)

    return this.httpClient.post(this.controllerapi + "getAllFoodOrder", body, {
      headers: header,
      responseType: "text",
    });
  }

  getAllFoodOrderByInvoice(
    idUser: string = "",
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    let body = new HttpParams()
    .set("idUser", idUser)
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getAllFoodOrderByInvoice", body, {
      headers: header,
      responseType: "text",
    });
  }

  finishOrderById(
    idUser: string = "",
    key: string = "",
    invoiceFnbadditional: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    let body = new HttpParams()
    .set("idUser", idUser)
    .set("key", key)
    .set("invoiceFnbadditional", invoiceFnbadditional)

    return this.httpClient.post(this.controllerapi + "finishOrderById", body, {
      headers: header,
      responseType: "text",
    });
  }

  getFNBUser(
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getFNBUser", body, {
      headers: header,
      responseType: "text",
    });
  }

  getFinanceUser(
    key: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("X-Api-Key", key);
    let body = new HttpParams()
    .set("key", key)

    return this.httpClient.post(this.controllerapi + "getFinanceUser", body, {
      headers: header,
      responseType: "text",
    });
  }

  postNotificationFO(
    nmUser: string = "",
    currentToken: string = "",
    message: string = "",
    idBooking: string ="",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + this.fcm_key);
    let body = new HttpParams()
      .set("nmUser", nmUser)
      .set("currentToken", currentToken)
      .set("message", message)
      .set("idBooking", idBooking)

    return this.httpClient.post(this.controllerapi + "postNotificationFO", body, { 
      headers: header, 
      responseType: "text" 
    });
  }

  postNotificationFNBOrderRoom(
    nmUser: string = "",
    currentToken: string = "",
    message: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + this.fcm_key);
    let body = new HttpParams()
      .set("nmUser", nmUser)
      .set("currentToken", currentToken)
      .set("message", message)

    return this.httpClient.post(this.controllerapi + "postNotificationFNBOrderRoom", body, { 
      headers: header, 
      responseType: "text" 
    });
  }

  postNotificationFNBOrderTable(
    nmUser: string = "",
    currentToken: string = "",
    message: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + this.fcm_key);
    let body = new HttpParams()
      .set("nmUser", nmUser)
      .set("currentToken", currentToken)
      .set("message", message)

    return this.httpClient.post(this.controllerapi + "postNotificationFNBOrderTable", body, { 
      headers: header, 
      responseType: "text" 
    });
  }

  postNotificationPayment(
    invoiceBooking: string = "",
    currentToken: string = "",
    idBusiness: string = "",
    rateafterdiscountBooking: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + this.fcm_key);
    let body = new HttpParams()
      .set("invoiceBooking", invoiceBooking)
      .set("currentToken", currentToken)
      .set("idBusiness", idBusiness)
      .set("rateafterdiscountBooking", rateafterdiscountBooking)

    return this.httpClient.post(this.controllerapi + "postNotificationPayment", body, { 
      headers: header, 
      responseType: "text" 
    });
  }

  updatestatuspayFNB(
    invoiceFnbadditional: string = "",
    statusPay: string = "",
  ): Observable<any> {
    let header = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + this.fcm_key);
    let body = new HttpParams()
      .set("invoiceFnbadditional", invoiceFnbadditional)
      .set("statusPay", statusPay)

    return this.httpClient.post(this.controllerapi + "updatestatuspayFNB", body, { 
      headers: header, 
      responseType: "text" 
    });
  }

  getcurrentbusd(): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "text/html");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("No-Auth", "True");

    return this.httpClient.get(this.latestBUSD, { headers: header });
  }

  getcurrentbnb(): Observable<any> {
    let header = new HttpHeaders();
    header.append("Accept", "text/html");
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("No-Auth", "True");

    return this.httpClient.get(this.latestBNB, { headers: header });
  }

  getChatTools(
  ): Observable<any> {
    let header = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/x-www-form-urlencoded")
    let body = new HttpParams()

    return this.httpClient.post(this.controllerapi + "getChatTools", body, {
      headers: header,
      responseType: "text",
    });
  }

}
